<template>
    <page-layout :back="backCaption">

        <BouncingBurgerOverlay :value="reorderId !== null" :message="$t('reordering')" />

        <v-container class="shop fill-height py-6 px-md-4">
            <v-row class="fill-height">
                <v-col cols="12" md="6" lg="8">
                    <shop-menu v-if="appFlatMenu !== null" :flat-menu="appFlatMenu"
                               :basket-quantities="basketQuantities" @selected-item="selectItem"
                               :language="appLanguage" :currency="appCurrency">
                    </shop-menu>
                    <shop-skeleton v-else :counts="[8,5]"/>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="hidden-sm-and-down">
                    <shop-basket :upsells="upsells" :basket="basket" @input="newBasket" @edit-item="editItem"
                                 @upsells="newUpsells" @checkout="checkout" :language="appLanguage"
                                 :currency="appCurrency"/>
                </v-col>
            </v-row>
            <v-footer app class="hidden-md-and-up pa-0">
                <shop-basket :upsells="upsells" :basket="basket" @input="newBasket" @checkout="reviewOrder"
                             @upsells="newUpsells" :language="appLanguage" :currency="appCurrency"
                             brief/>
            </v-footer>
        </v-container>

        <v-dialog :width="isXs ? '' : 800"
                  transition="dialog-bottom-transition"
                  v-model="selectedItemShow">
            <v-layout class="overflow-x-hidden">
                <shop-item-configure v-if="selectedItemShow"
                                     :editing="selectedBasketIndex !== null"
                                     @add-basket="what => addedItem(what)"
                                     @close="selectedItemShow = false"
                                     :item="selectedItem"
                                     :init-qty="selectedQuantity"
                                     :pre-config="selectedPreConfig"
                                     :category-code="selectedCategoryCode"
                                     :flat-menu="appFlatMenu"
                                     :language="appLanguage" :currency="appCurrency"
                />
            </v-layout>
        </v-dialog>

    </page-layout>

</template>

<script>
    import APIOrder from "../../api/APIOrder";
    import ShopSkeleton from "../../components/Shop/ShopSkeleton";
    import ShopItemConfigure from "../../components/Shop/ShopItemConfigure";
    import ShopMixin from "../../mixins/ShopMixin";
    import BouncingBurgerOverlay from "../../components/UI/BouncingBurgerOverlay";
    import BreakfastMixin from "../../mixins/BreakfastMixin";

    export default {
        name: 'Order',
        data: function () {
            return {
                isLoading: true,
                menuJson: null,
            }
        },
        mixins: [ ShopMixin, BreakfastMixin ],
        components: {
            ShopSkeleton, ShopItemConfigure, BouncingBurgerOverlay
        },
        mounted() {

            // more guard stuff needed here
            if (this.sessionUndefined('storeId')) {
                this.$router.replace({name: 'start-order'});
                return;
            }

            const me = this;
            APIOrder.getFlatMenu(this, this.$store.getters['session/storeId'], this.$store.getters['session/orderType'])
                .then(response => {
                    me.appFlatMenu = Object.freeze(response.data.data);
                    me.reorderIfRequired(this.breakfastAllowed)
                        .catch(error => {
                            me.appShowErrorDialog(error, 'primary');
                            console.log(error);
                        });
                })
                .catch(() => {
                    me.appFlatMenu = null
                })
                .finally(() => me.isLoading = false);
        },
        methods: {
            checkout() {
                this.$router.push({name: 'checkout'});
            },
            reviewOrder() {
                this.$router.push({name: 'review-order'});
            },
        },
        computed: {
            backCaption() {
                return this.isAppMode ? this.$i18n.t('btn.back') : '';
            }
        }
    };
</script>

<style scoped>

</style>

