<template>
    <v-container class="mb-16">
        <v-col cols="12" class="pl-0">
            <v-skeleton-loader type="image" height="40" class="sticky d-inline-block"/>
            <v-skeleton-loader type="image" height="40" class="sticky d-inline-block ml-4" v-for="i in skeleButtonCount - 1" :key="i"/>
        </v-col>
        <v-row class="pt-12"
               v-for="(count, countIdx) in counts" :key="countIdx">
            <v-col cols="12">
                <v-skeleton-loader type="image" height="48" class="category" />
                <v-skeleton-loader type="image" height="24" width="90%" class="mt-3"/>
            </v-col>
            <v-col>
                <v-row class="pt-2">
                    <v-col cols="12" lg="6" class="shopItemContainer" v-for="idx in count" :key="idx">
                        <v-skeleton-loader type="title, image" height="144"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "ShopSkeleton",
        props: {
            counts: {type: Array, required: true}
        },
        computed: {
            skeleButtonCount() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return 2;
                    case 'sm':
                    case 'md':
                        return 3;
                    case 'lg':
                        return 4;
                    default:
                        return 5;
                }
            }
        }
    }
</script>

<style scoped>

.sticky {
    width: 30%;
}
.category {
    width: 45%;
}
@media(min-width: 1200px) {
    .sticky {
        width: 120px;
    }
    .category {
        width: 30%;
    }
}

</style>
